<template>
  <b-overlay
    variant="white"
    :show="showLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <div
      v-if="services.length"
      class="accordion"
      role="tablist"
    >
      <draggable
        animation="200"
        ghost-class="ghost-sort"
        :list="services"
        @change="changedSorting"
      >
        <div
          v-for="service in services"
          :key="service.slug"
          class="accordion__item "
        >
          <div
            v-b-toggle="`accordion-${service.slug}`"
            role="tab"
            class="accordion-label"
          >
            <div class="accordion-label__content">
              <div class="row w-100 align-items-center">
                <div class="col-12 col-lg-4">
                  <div class="d-flex align-items-center">
                    <feather-icon
                      icon="MenuIcon"
                      class="mr-2"
                    />
                    <feather-icon
                      icon="FolderIcon"
                      size="21"
                      class="mr-1"
                    />
                    {{ service.name }}
                  </div>
                </div>
                <div class="col-12 col-lg-4">
                  <code>{{ service.slug }}</code>
                </div>
                <div class="col-12 col-lg-4">
                  <div
                    v-if="!service.is_read_only"
                    class="accordion-label-custom"
                  >
                    <div class="accordion-label-custom__icon">
                      <feather-icon
                        icon="StarIcon"
                        size="12"
                      />
                    </div>
                    <div class="accordion-label-custom__text">
                      Custom
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-collapse
            :id="`accordion-${service.slug}`"
            accordion="my-accordion"
            role="tabpanel"
            @show="currentServiceSlug = service.slug"
          >
            <sub-services-sort
              v-if="currentServiceSlug === service.slug"
              :key="service.slug"
              :parent-service-slug="service.slug"
            />
          </b-collapse>
        </div>
      </draggable>
    </div>
    <div v-else>
      Пока нет ни одной услуги
    </div>
  </b-overlay>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Draggable from 'vuedraggable'
import SiteService from '@/api/http/models/site/service/SiteService'
import {
  BCollapse, VBToggle, VBTooltip, BOverlay,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import SubServicesSort from './SubServicesSort.vue'

export default {
  components: {
    BCollapse,
    BOverlay,
    SubServicesSort,
    Draggable,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      showLoading: true,
      services: [],
      selectedServices: [],
      currentServiceSlug: null,
      search: '',
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    const response = await SiteService.getForSort(this.currentSite.slug)

    if (response.data) {
      this.selectedServices = response.data.filter(service => service.is_selected).map(service => ({
        serviceSlug: service.slug,
        AllSelectedServices: service.available_children === service.selected_children,
      }))
      this.services = response.data
      this.showLoading = false
      this.$emit('selected', this.selectedServices)
    }
  },
  methods: {
    async changedSorting() {
      const services = this.services.map((service, index) => ({
        slug: service.slug,
        order: index + 1,
      }))

      const response = await SiteService.sort(this.currentSite.slug, {
        parent_slug: null,
        services,
      })

      if (response.status === 'success') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Услуги отсортированы',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }
    },
  },
}
</script>
