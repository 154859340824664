<template>
  <div class="py-3 pl-3">
    <b-overlay
      :show="showLoading"
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <div class="h4 mb-2">
        Подкатегории
      </div>
      <ul
        v-if="services.length"
        ref="container"
        class="subitems-list"
      >
        <draggable
          animation="200"
          ghost-class="ghost-sort"
          :list="services"
          @change="changedSorting"
        >
          <li
            v-for="service in services"
            :key="service.slug"
          >
            <div class="subitem">
              <div class="subitem__content">
                <div class="subitem__checkbox">
                  <feather-icon
                    icon="MenuIcon"
                  />
                </div>
                <div class="subitem__title">
                  <div class="row align-items-center">
                    <div class="col-4">
                      {{ service.name }}
                    </div>
                    <div class="col-4">
                      <code>{{ service.slug }}</code>
                    </div>
                  </div>
                </div>
              </div>
              <div class="subitem__custom  d-flex align-items-center">
                <div
                  v-if="!service.is_read_only"
                  class="accordion-label-custom"
                >
                  <div class="accordion-label-custom__icon">
                    <feather-icon
                      icon="StarIcon"
                      size="12"
                    />
                  </div>
                  <div class="accordion-label-custom__text">
                    Custom
                  </div>
                </div>
              </div>
              <div class="subitem__actions" />
            </div>
          </li>
        </draggable>
      </ul>
      <div v-else>
        У этой услуги пока нет подкатегорий
      </div>
    </b-overlay>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Draggable from 'vuedraggable'
import {
  BOverlay, VBTooltip,
} from 'bootstrap-vue'
import SiteService from '@/api/http/models/site/service/SiteService'
import { mapGetters } from 'vuex'

export default {
  components: {
    BOverlay,
    Draggable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    parentServiceSlug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showLoading: true,
      services: [],
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    this.showLoading = true
    const response = await SiteService.getForSort(this.currentSite.slug, this.parentServiceSlug)

    if (response.data) {
      this.services = await response.data
    }
    this.showLoading = false
  },
  methods: {
    async changedSorting() {
      const services = this.services.map((service, index) => ({
        slug: service.slug,
        order: index + 1,
      }))

      const response = await SiteService.sort(this.currentSite.slug, {
        parent_slug: this.parentServiceSlug,
        services,
      })

      if (response.status === 'success') {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Услуги отсортированы',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }
    },
  },
}
</script>
